<template>
    <BaseSection width="bleed" class="section--map">
        <LMap
            ref="map"
            class="section--map__map"
            :zoom="actualZoom"
            :center="actualStartPosition"
            :use-global-leaflet="false"
            :options="{ scrollWheelZoom: false }"
            :zoom-snap="0"
        >
            <!-- beware of the magic `use-global-leaflet` boolean-->>
            <LTileLayer
                url="https://d.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                layer-type="base"
                name="OpenStreetMap"
            />

            <LMarker
                v-for="(location, index) in locations"
                :key="`map-location-${index}`"
                :lat-lng="location.location"
            >
                <LIcon
                    icon-url="/img/marker-blue.svg"
                    :icon-size="[35, 35]"
                    :popup-anchor="[21, 42]"
                    :tooltip-anchor="[15, -5]"
                    :data-has-popup="showReadMoreLink(location.readMoreLink)"
                />

                <LTooltip>
                    <strong>{{ locationLabel(location) }}</strong>
                </LTooltip>

                <LPopup v-if="showReadMoreLink(location.readMoreLink)">
                    <CraftLink v-if="showReadMoreLink(location.readMoreLink)" :link="location.readMoreLink">
                        <strong>{{ locationLabel(location) }}</strong>
                        - {{ location.readMoreLink.text }}
                    </CraftLink>
                    <template v-else>
                        <strong>{{ locationLabel(location) }}</strong>
                    </template>
                </LPopup>
            </LMarker>
        </LMap>
    </BaseSection>
</template>

<script setup>
import 'leaflet/dist/leaflet.css';
import { LIcon, LMap, LMarker, LPopup, LTileLayer, LTooltip } from '@vue-leaflet/vue-leaflet';

const props = defineProps({
    locations: {
        type: Array,
        required: true,
        default: () => []
    },
    startPosition: {
        type: Object,
        required: false,
        default: () => {}
    }
});

const actualStartPosition = computed(() => {
    if (!props.startPosition?.lat || !props.startPosition?.lng) {
        // Onze Lieve Vrouwentoren, Amersfoort
        // https://nl.wikipedia.org/wiki/Geografisch_middelpunt_van_Nederland
        return [52.1551995, 5.385052417];
    }

    return props.startPosition;
});

const actualZoom = computed(() => {
    if (!props.startPosition?.zoom || (!props.startPosition?.lat || !props.startPosition?.lng)) {
        return 8;
    }

    return props.startPosition.zoom;
});

const showReadMoreLink = link => link.element || link.url;

const locationLabel = location => location.customLabel || location.location.parts.city;
</script>

<style lang="less">
.section--map .section__container,
.section--map .section__body {
    height: 50vh;

    @media @q-md-min {
        min-height: 800px;
    }
}

/*
* leaflet-specific styles
*/
.section--map__map {
    .leaflet-popup {
        border-radius: unset;
    }

    .leaflet-popup-close-button,
    .leaflet-popup-tip-container {
        display: none;
    }

    .leaflet-tooltip,
    .leaflet-popup-content-wrapper {
        background-color: var(--color-primary);
        color: var(--color-lightest);
        border-radius: unset;
        font-size: var(--font-size-lg);
        border: 0 none;
    }

    .leaflet-popup-content {
        margin: var(--spacing-sm);

        & a {
            color: var(--color-lightest);
        }
    }

    .leaflet-tooltip[id^='leaflet-tooltip-'] {
        padding: var(--spacing-sm);
        opacity: .8;
    }
}
</style>
